import IConfig from "./interfaces/config";

function getConfig(): IConfig {
    return process.env.NODE_ENV === "development"
        ? { url: "http://localhost:8080" }
        : {};
}

const AppConfig: IConfig = getConfig();

export default AppConfig;
