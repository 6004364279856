import LoginForm from './util/LoginForm';
import { Navigate } from 'react-router-dom';

const LoginPage = () => {
  if (localStorage.getItem("auth") !== null) {
    return <Navigate to="/home" />;
  }

  return (
    <div className="LoginPage">
      <LoginForm />
    </div>
  );
}

export default LoginPage;