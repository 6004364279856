import axiosInstance from "../components/util/AxiosInstance";
import {
    RequestErrorHandler,
    dummyErrorHandling,
} from "../util/RequestErrorHandler";

export const loginRequest = (
    username: string,
    password: string,
    timeZone: string,
    lastConnection: number,
    handleError: RequestErrorHandler = dummyErrorHandling
) => {
    const bodyPayload = {
        username: username,
        password: password,
        timeZone: timeZone,
        lastConnection: lastConnection,
    };
    return axiosInstance
        .post("/api/login", bodyPayload)
        .then((response) => response.data)
        .catch(handleError);
};
