import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import {
    IApplicationState,
    ICollectionBeingSolvedInfo,
    ILoggedUser,
    ILoggedUserInfo,
} from "../../interfaces/state";
import { TEST_POST_PROBLEM } from "./Dictionary";

// Store solo para loggedUser usando localStorage
export const useLoggedUserStore = create<ILoggedUser>()(
    persist(
        (set) => ({
            loggedUser: {
                username: null,
                role: null,
                id: null,
                language: "es",
                timeZone: "",
            },
            setLoggedUser: (loggedUser: ILoggedUserInfo) =>
                set(() => ({ loggedUser })),
        }),
        {
            name: "loggedUser-storage",
            storage: createJSONStorage(() => localStorage),
        }
    )
);

// Store para el resto del estado usando sessionStorage
export const useBearStore = create<IApplicationState>()(
    persist(
        (set) => ({
            collectionBeingSolvedInfo: {
                lastProblem: 0,
                lastCollection: 0,
                collectionLength: 0,
                roomUUID: null,
                isIndividual: false,
                isCollaborative: false,
                isCollectionFinished: false,
                isCurrentProblemFinished: false,
                problemList: [],
                isCollectionTimerEnabled: 0,
                testPostProblem: TEST_POST_PROBLEM.NO_TEST_POST_PROBLEM,
                isTestAlreadySentForCurrentProblem: false,
                maxAllowedActions: 1,
                maxAllowedHelp: 1,
            },
            isNextProblem: false,
            initialProblemDurationInSeconds: 1000000000000,
            isTimerPlaying: false,
            setCollectionBeingSolvedInfo: (
                collectionBeingSolvedInfo: ICollectionBeingSolvedInfo
            ) => set(() => ({ collectionBeingSolvedInfo })),
            setIsNextProblem: (isNextProblem: boolean) =>
                set(() => ({ isNextProblem })),
            setInitialProblemDurationInSeconds: (
                initialProblemDurationInSeconds: number
            ) => set(() => ({ initialProblemDurationInSeconds })),
            resetInitialProblemDurationInSeconds: () =>
                set(() => ({ initialProblemDurationInSeconds: 0 })),
            setIsTimerPlaying: (isTimerPlaying: boolean) =>
                set(() => ({ isTimerPlaying: isTimerPlaying })),
        }),
        {
            name: "bear-storage",
            storage: createJSONStorage(() => localStorage),
        }
    )
);
