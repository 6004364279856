export const CONFIG_MESSAGE = Object.freeze({
    SYSTEM: "system",
    ENTER: "Enter",
    EXPIRED_COLLABORATIVE: "expiredColaborative",
    RESET: "reset",
    SINGLE: "SINGLE",
    SEQUENCE: "Sequence",
    LOGIN_PATH: "/login",
    PASSWORD: "password",
    ACCOUNT: "account",
    ME: "me",
    USER: "User",
    USERS: "users",
});

export const MODAL_TYPE = Object.freeze({
    RESET: "reset",
    TIMED_OUT: "timed_out",
    FINISHED_COLLECTION_OR_INDIVIDUAL: "finished_collection_individual",
});

export const ROLE = Object.freeze({
    USER: "user",
    ADMIN: "admin",
    MODERATOR: "moderator",
});

export const LANGUAGE = Object.freeze({
    SPANISH: "es-ES",
    ENGLISH: "en-EN",
});

export const TEST_POST_PROBLEM = Object.freeze({
    NO_TEST_POST_PROBLEM: 0,
});
