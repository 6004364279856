import React from "react";
import strings from "./util/Language";
import "../styles/Error404.css";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
    const navigate = useNavigate();

    return (
        <section className="wrapper">
            <div className="container">
                <div id="scene" className="scene" data-hover-only="false">
                    <div className="circle" data-depth="1.2"></div>
                    <div className="one" data-depth="0.9">
                        <div className="content">
                            <span className="piece"></span>
                            <span className="piece"></span>
                            <span className="piece"></span>
                        </div>
                    </div>

                    <div className="two" data-depth="0.60">
                        <div className="content">
                            <span className="piece"></span>
                            <span className="piece"></span>
                            <span className="piece"></span>
                        </div>
                    </div>

                    <p className="p404" data-depth="0.50">
                        404
                    </p>
                </div>

                <div className="text">
                    <article>
                        <p>{strings.Error404.error404}</p>
                        <button
                            onClick={() => {
                                navigate("/login");
                            }}>
                            {strings.Error404.understoodBack404}
                        </button>
                    </article>
                </div>
            </div>
        </section>
    );
};

export default Error404;
