import axios from "axios";
import AppConfig from "../../AppConfig";

export const axiosInstance = axios.create({
    baseURL: AppConfig.url,
    headers: {
        Accept: "application/json",
    },
});

export default axiosInstance;
