import React, { Suspense, lazy } from "react";
import LoginPage from "./components/LoginPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error404 from "./components/Error404";
import "./styles/App.css";

const HomePage = lazy(() => import("./components/HomePage"));
const Logout = lazy(() => import("./components/Logout"));
const ExercisesDataTable = lazy(() =>
    import("./components/ExercisesDataTable")
);
const Account = lazy(() => import("./components/Account"));
const AdminCollectionPage = lazy(() =>
    import("./components/admin/pages/CollectionPage")
);
const AdminDidacticSeq = lazy(() =>
    import("./components/admin/pages/DidacticSeqPage")
);
const ConversationPage = lazy(() => import("./components/ConversationPage"));
const AdminCollectionSettingPage = lazy(() =>
    import("./components/admin/AdminCollectionSettingPage")
);
const AdminCollectionEditorPage = lazy(() =>
    import("./components/admin/AdminCollectionEditorPage")
);
const AdminCollectionImagePage = lazy(() =>
    import("./components/admin/AdminCollectionImagePage")
);
const ListUsers = lazy(() => import("./components/admin/pages/ListUsersPage"));
const SignUp = lazy(() => import("./components/SignUp"));
const SignUpMassive = lazy(() => import("./components/admin/SignUpMassive"));
const CollectionsDataTable = lazy(() =>
    import("./components/CollectionsDataTable")
);
const AdminProblemPage = lazy(() =>
    import("./components/admin/pages/ProblemPage")
);
const AdminNewProblemPage = lazy(() =>
    import("./components/admin/AdminNewProblemPage")
);
const AdminNewInstructionalTemplatePage = lazy(() =>
    import("./components/admin/AdminNewInstructionalTemplatePage")
);
const AdminEditProblemPage = lazy(() =>
    import("./components/admin/AdminEditProblemPage")
);
const AdminNewCollectionPage = lazy(() =>
    import("./components/admin/AdminNewCollection")
);
const AdminNewDidacticSeq = lazy(() =>
    import("./components/admin/AdminNewDidacticSeq")
);
const AdminEditDidacticSeqPage = lazy(() =>
    import("./components/admin/AdminEditDidacticSeq")
);
const AdminEditCollectionPage = lazy(() =>
    import("./components/admin/AdminEditCollectionPage")
);
const DidacticSeqDataTable = lazy(() =>
    import("./components/tutorDidacticSeq/DidacticSeqDataTable")
);
const StepsViewSequence = lazy(() =>
    import("./components/tutorDidacticSeq/StepsViewSequence")
);
const ManagerDidacticSeq = lazy(() =>
    import("./components/tutorDidacticSeq/ManagerDidacticSeq")
);
const AdminGroupPage = lazy(() => import("./components/admin/pages/GroupPage"));
const AdminNewGroupPage = lazy(() =>
    import("./components/admin/AdminNewGroupPage")
);
const AdminEditGroupPage = lazy(() =>
    import("./components/admin/AdminEditGroupPage")
);
const AdminLogsPage = lazy(() =>
    import("./components/admin/pages/AdminLogsPage")
);
const AdminInstructionalTemplatePage = lazy(() =>
    import("./components/admin/AdminInstructionalTemplatePage")
);
const AdminEditInstructionalTemplatePage = lazy(() =>
    import("./components/admin/AdminEditInstructionalTemplatePage")
);
const NavigationBar = lazy(() => import("./components/NavigationBar"));

const App = () => {
    return (
        <div style={{ height: "95%" }}>
            <BrowserRouter basename="/">
                <Suspense fallback={<NavigationBar />}>
                    <Routes>
                        <Route exact path="/login" element={<LoginPage />} />
                        <Route exact path="/logout" element={<Logout />} />
                        <Route element={<NavigationBar />}>
                            <Route index element={<HomePage />} />
                            <Route path="home" element={<HomePage />} />
                            <Route
                                path="/account/:userId"
                                element={<Account />}
                            />
                            <Route
                                path="collections"
                                element={<CollectionsDataTable />}
                            />
                            <Route
                                path="collections/:collectionId"
                                element={<ExercisesDataTable />}
                            />
                            <Route
                                path="collections/:collectionId/problem"
                                element={<ConversationPage />}
                            />
                            <Route
                                path="collections/:collectionId/problem/:id"
                                element={<ConversationPage />}
                            />
                            <Route
                                path="didacticSeqs"
                                element={<DidacticSeqDataTable />}
                            />
                            <Route
                                path="didacticSeqs/:id"
                                element={<StepsViewSequence />}
                            />
                            <Route
                                path="didacticSeqs/:id/step/:stepId"
                                element={<ManagerDidacticSeq />}
                            />
                            <Route
                                path="collectionDidactic/:collectionId/problem/:id"
                                element={<ConversationPage navigationReturn={"/didacticSeqs"}/>}
                            />
                            <Route path="admin">
                                <Route path="users" element={<ListUsers />} />
                                <Route path="signUp" element={<SignUp />} />
                                <Route
                                    path="signUpMassive"
                                    element={<SignUpMassive />}
                                />
                                <Route
                                    path="didacticSeq"
                                    element={<AdminDidacticSeq />}
                                />
                                <Route
                                    path="didacticSeq/new-didacticSeq"
                                    element={<AdminNewDidacticSeq />}
                                />
                                <Route
                                    path="didacticSeq/edit/:id"
                                    element={<AdminEditDidacticSeqPage />}
                                />
                                <Route
                                    path="collections"
                                    element={<AdminCollectionPage />}
                                />
                                <Route
                                    path="collections/new-collection"
                                    element={<AdminNewCollectionPage />}
                                />
                                <Route
                                    path="collections/:collectionId"
                                    element={<AdminEditCollectionPage />}
                                />
                                <Route
                                    path="collections/:collectionId/setting"
                                    element={<AdminCollectionSettingPage />}
                                />
                                <Route
                                    path="collections/:collectionId/content"
                                    element={<AdminCollectionEditorPage />}
                                />
                                <Route
                                    path="collections/edit/:collectionId"
                                    element={<AdminEditCollectionPage />}
                                />
                                <Route
                                    path="collections/images"
                                    element={<AdminCollectionImagePage />}
                                />
                                <Route
                                    path="groups"
                                    element={<AdminGroupPage />}
                                />
                                <Route
                                    path="groups/new-group"
                                    element={<AdminNewGroupPage />}
                                />
                                <Route
                                    path="groups/edit/:groupId"
                                    element={<AdminEditGroupPage />}
                                />
                                <Route
                                    path="problems"
                                    element={<AdminProblemPage />}
                                />
                                <Route
                                    path="problems/new-problem"
                                    element={<AdminNewProblemPage />}
                                />
                                <Route
                                    path="problems/edit/:problemId"
                                    element={<AdminEditProblemPage />}
                                />
                                <Route
                                    path="logs"
                                    element={<AdminLogsPage />}
                                />
                                <Route
                                    path="instructional-model"
                                    element={<AdminInstructionalTemplatePage />}
                                />
                                <Route
                                    path="instructional-model/new-instructional-model"
                                    element={
                                        <AdminNewInstructionalTemplatePage />
                                    }
                                />
                                <Route
                                    path="instructional-model/edit/:instructionalmodelId"
                                    element={
                                        <AdminEditInstructionalTemplatePage />
                                    }
                                />
                            </Route>
                        </Route>
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </div>
    );
};

export default App;
