import axiosInstance from "./AxiosInstance";
import { Form, FormGroup, Alert } from "react-bootstrap";
import "../../styles/LoginForm.css";
import { useState } from "react";
import { useLoggedUserStore,useBearStore } from "./Global";
import { useNavigate } from "react-router-dom";
import { LANGUAGE } from "./Dictionary";
import strings from "./Language";
import { loginRequest } from "../../requests/auth";
import { LoginResponse } from "../../interfaces/responses";
import { ILoggedUser,IApplicationState } from "../../interfaces/state";
const Cookies = require("js-cookie");
function LoginForm() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [timeZone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const [lastConnection] = useState(new Date().getTime());
    const [errorOcurred, setErrorOcurred] = useState(false);
    const setLoggedUser = useLoggedUserStore((state: ILoggedUser) => state.setLoggedUser);
    const collectionBeingSolvedInfo = useBearStore(
        (state : IApplicationState) => state.collectionBeingSolvedInfo
    );
    const setCollectionBeingSolvedInfo = useBearStore(
        (state : IApplicationState) => state.setCollectionBeingSolvedInfo
    );

    function handleFormSubmit(e: any) {
        e.preventDefault();

        loginRequest(username, password, timeZone, lastConnection, () => {
            setErrorOcurred(true);
        }).then((response: LoginResponse) => {
            let language;
            localStorage.setItem("auth", response.access_token);
            axiosInstance.defaults.headers.common["Authorization"] =
                response.token_type + " " + response.access_token;
            if (response.language === LANGUAGE.ENGLISH) {
                Cookies.set("Accept-Language", "en, es", { expires: 7 });
                strings.setLanguage("en");
                language = "en";
            } else if (response.language === LANGUAGE.SPANISH) {
                Cookies.set("Accept-Language", "es, en", { expires: 7 });
                strings.setLanguage("es");
                language = "es";
            }

            let collectionBeingSolvedInfoUpdate = collectionBeingSolvedInfo;

            setCollectionBeingSolvedInfo({
                ...collectionBeingSolvedInfoUpdate,
                isCollectionFinished: false,
            });

            setLoggedUser({
                username: username,
                role: response.role.slice(5).toLowerCase(),
                id: response.id,
                language: language,
                timeZone: response.timeZone,
            });

            navigate("/home");
        });
    }

    return (
        <div className="login_card">
            <div className="login">
                <Form className="LoginForm" id="loginForm">
                    <h2>HINTS</h2>
                    <Alert
                        id="login_error"
                        variant="danger"
                        show={errorOcurred}>
                        {strings.LoginForm.login_error}
                    </Alert>
                    <FormGroup className="floating-label">
                        <input
                            autoComplete="on"
                            className="login-input"
                            id="username"
                            type="text"
                            placeholder="Username"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <label>{strings.username}</label>
                    </FormGroup>
                    <FormGroup className="floating-label">
                        <input
                            autoComplete="on"
                            className="login-input"
                            id="password"
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label>{strings.password}</label>
                    </FormGroup>
                    <FormGroup>
                        <button
                            type="submit"
                            className="login-button"
                            onClick={(e) => handleFormSubmit(e)}>
                            {strings.LoginForm.login}
                        </button>
                    </FormGroup>
                </Form>
            </div>
        </div>
    );
}

export default LoginForm;
